var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$auth.user.tfa_authenticated)?_c('v-navigation-drawer',{attrs:{"color":"grey lighten-5","app":"","clipped":"","mobile-breakpoint":"1200"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":_vm.localePath({ name: 'index' })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-human-queue")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('QUEUE_STATS'))+" ")])],1)],1),_vm._v(" "),(_vm.$auth.user.permissions.other_chats.includes('overview'))?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'messages',
          })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-forum")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('chat_overview'))+" ")])],1),_vm._v(" "),_c('v-list-item-action',[_c('v-chip',{attrs:{"color":"success","small":true}},[_vm._v(_vm._s(_vm.$auth.user.open_messages)+"\n          ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.user.permissions.reviews.includes('view'))?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'history',
          })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-history")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('message_history')))])],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.user.permissions.other_chats.includes('reports'))?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'message-reports',
          })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-alert")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('message_reports')))])],1),_vm._v(" "),(_vm.$auth.user.open_reports > 0)?_c('v-list-item-action',[_c('v-chip',{attrs:{"color":"warning","small":true}},[_vm._v("\n            "+_vm._s(_vm.$auth.user.open_reports)+"\n          ")])],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'stats',
          })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-chart-bar")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Stats'))+" ")])],1)],1),_vm._v(" "),(_vm.$auth.user.profile_sets === 'yes')?_c('v-list-item',{attrs:{"to":_vm.localePath({
            name: 'profilesets',
          })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-ghost-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Profile sets')))])],1)],1):_vm._e(),_vm._v(" "),(
          _vm.$auth.user.permissions &&
          (_vm.$auth.user.permissions.manage_operators === 'yes' ||
            _vm.$auth.user.permissions.chat_stats.includes('skiplog'))
        )?_c('div',[_c('div',[_c('v-list-item',{staticClass:"menu-header"},[_vm._v(" Admin settings ")])],1),_vm._v(" "),(_vm.$auth.user.permissions.manage_operators === 'yes')?_c('v-list-item',{attrs:{"to":_vm.localePath({
              name: 'operators',
            })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Operators ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.user.permissions.manage_operators === 'yes')?_c('v-list-item',{attrs:{"to":_vm.localePath({
              name: 'permissions',
            })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-lock-open-check-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Account level permissions ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.user.permissions.manage_operators === 'yes')?_c('v-list-item',{attrs:{"to":_vm.localePath({
              name: 'operator-login-history',
            })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-delta")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Login history ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.user.permissions.chat_stats.includes('skiplog'))?_c('v-list-item',{attrs:{"to":_vm.localePath({
              name: 'skiplog',
            })}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-skip-forward")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('skiplog'))+" ")])],1)],1):_vm._e()],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('v-app-bar',{attrs:{"app":"","color":"green lighten-5","clipped-left":""}},[(_vm.$auth.user.tfa_authenticated)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.state.app_bar_title))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(
        _vm.$auth.user.tfa_authenticated &&
        _vm.$auth.user.message_types.includes('poke')
      )?_c('v-badge',{staticStyle:{"margin-top":"3px","margin-right":"20px"},attrs:{"color":"blue","content":_vm.$auth.user.open_pokes,"value":_vm.$auth.user.open_pokes > 0,"overlap":""}},[_c('v-btn',{attrs:{"rounded":"","text":"","to":_vm.localePath({
            name: 'poke-queue',
          })}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v("mdi-reminder")]),_vm._v("\n        "+_vm._s(_vm.$t('Open poke queue'))+"\n      ")],1)],1):_vm._e(),_vm._v(" "),(
        _vm.$auth.user.tfa_authenticated &&
        _vm.$auth.user.message_types.includes('reply')
      )?_c('v-badge',{staticStyle:{"margin-top":"3px","margin-right":"20px"},attrs:{"color":"blue","content":_vm.$auth.user.open_messages,"value":_vm.$auth.user.open_messages > 0,"overlap":""}},[_c('v-btn',{attrs:{"rounded":"","text":"","to":_vm.localePath({
            name: 'queue',
          })}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v("mdi-forum")]),_vm._v("\n        "+_vm._s(_vm.$t('Open chat queue'))+"\n      ")],1)],1):_vm._e(),_vm._v(" "),(
        _vm.$auth.user.tfa_authenticated &&
        _vm.$auth.user.message_types.includes('poke') &&
        _vm.$auth.user.message_types.includes('reply')
      )?_c('v-badge',{staticStyle:{"margin-top":"3px","margin-right":"20px"},attrs:{"color":"green","content":_vm.$auth.user.open_messages + '+',"value":_vm.$auth.user.open_messages > 0,"overlap":""}},[_c('v-btn',{attrs:{"rounded":"","text":"","to":_vm.localePath({
            name: 'queue',
            query: {
              smart: 1,
            },
          })}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v("mdi-all-inclusive")]),_vm._v("\n        "+_vm._s(_vm.$t('Open combi queue'))+"\n      ")],1)],1):_vm._e(),_vm._v(" "),_c('v-btn',{staticStyle:{"margin-top":"3px"},attrs:{"title":"Logout","rounded":"","text":""},on:{"click":function($event){_vm.$auth.logout(), _vm.$store.dispatch('settings/clear_siteowners')}}},[_c('v-icon',[_vm._v("mdi-logout")]),_vm._v("\n      "+_vm._s(_vm.$t('Logout'))+"\n    ")],1)],1),_vm._v(" "),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[(_vm.showReleaseWarning)?_c('v-alert',{attrs:{"border":"bottom","type":"error"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v("\n            A new version has been released! Please refresh this page.\n          ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.refreshPage()}}},[_vm._v("Refresh page")])],1)],1)],1):_vm._e(),_vm._v(" "),_c('nuxt')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }