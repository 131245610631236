export const state = () => ({
  permissions: null,
  languages: null,
  countries: null,
  regions: null,
  fetishes: null,
  operator_names: null,
  siteowners: null,
  reportreasons: null,
  configurable_message_types: null,
  configurable_segments: null,
  history_size_short: null,
  history_size_limited: null,
});

export const mutations = {
  set_permissions(state, perm) {
    state.permissions = perm.permissions;
    state.configurable_message_types = perm.configurable_message_types;
    state.configurable_segments = perm.configurable_segments;
    state.history_size_short = perm.history_size_short;
    state.history_size_limited = perm.history_size_limited;
  },
  set_languages(state, rd) {
    state.languages = rd;
  },
  set_countries(state, rd) {
    state.countries = rd;
  },
  set_regions(state, rd) {
    state.regions = rd;
  },
  set_fetishes(state, rd) {
    state.fetishes = rd;
  },
  set_operator_names(state, rd) {
    state.operator_names = rd;
  },
  set_siteowners(state, rd) {
    state.siteowners = rd;
  },
  set_reportreasons(state, rd) {
    state.reportreasons = rd;
  },
};

export const actions = {
  load_permissions({ commit, state }, type) {
    if (state.permissions) {
      return 1;
    }
    return this.$axios.get('/api/permissions.php').then((res) => {
      commit('set_permissions', res.data);
    });
  },
  clear_siteowners({ commit, state }, type) {
    commit('set_siteowners', null);
  },

  load_siteowners({ commit, state }, type) {
    if (state.siteowners) {
      return 1;
    }
    return this.$axios.get('/api/siteowners.php').then((res) => {
      commit('set_siteowners', res.data);
    });
  },
  load_reportreasons({ commit, state }, type) {
    if (state.reportreasons) {
      return 1;
    }
    return this.$axios.get('/api/reportreasons.php').then((res) => {
      commit('set_reportreasons', res.data.reasons);
    });
  },

  load_languages({ commit, state }, type) {
    if (state.languages) {
      return 1;
    }
    return this.$axios
      .get('/api/languages.php', {
        params: {
          locale: this.$i18n.locale,
        },
      })
      .then((res) => {
        commit('set_languages', res.data);
      });
  },

  load_countries({ commit, state }, type) {
    if (state.countries) {
      return 1;
    }
    return this.$axios
      .get('/api/countries.php', {
        params: {
          locale: this.$i18n.locale,
        },
      })
      .then((res) => {
        commit('set_countries', res.data);
      });
  },

  load_regions({ commit, state }, type) {
    if (state.regions) {
      return 1;
    }
    return this.$axios.get('/api/regions.php').then((res) => {
      commit('set_regions', res.data);
    });
  },

  load_fetishes({ commit, state }, type) {
    if (state.fetishes) {
      return 1;
    }
    return this.$axios.get('/api/fetishes.php').then((res) => {
      commit('set_fetishes', res.data.fetishes);
    });
  },

  force_reload_operator_names({ commit, state }, type) {
    return this.$axios.get('/api/operator_names.php?new=2').then((res) => {
      commit('set_operator_names', res.data);
    });
  },
  load_operator_names({ commit, state }, type) {
    if (state.operator_names !== null) {
      return 1;
    }
    return this.$axios.get('/api/operator_names.php?new=1').then((res) => {
      commit('set_operator_names', res.data);
    });
  },
};
